import React from "react";
import MainTemplate from "../templates/MainTemplate";
import {createTheme, makeStyles} from "@material-ui/core/styles";
import Link from '@material-ui/core/Link';
import * as colors from "@material-ui/core/colors";
import Typography from "@material-ui/core/Typography";

const theme = createTheme({
    typography: {
        fontFamily: [
            "Noto Sans JP",
            "Lato",
            "游ゴシック Medium",
            "游ゴシック体",
            "Yu Gothic Medium",
            "YuGothic",
            "ヒラギノ角ゴ ProN",
            "Hiragino Kaku Gothic ProN",
            "メイリオ",
            "Meiryo",
            "ＭＳ Ｐゴシック",
            "MS PGothic",
            "sans-serif",
        ].join(","),
    },
    palette: {
        primary: { main: colors.blue[800] }, // テーマの色
    },
});

const useStyles = makeStyles({
    link: {
        // textDecoration: "none",
        color: theme.palette.text.secondary,
    },
    pageTitle: {
        marginBottom: theme.spacing(1),
        marginLeft: "auto",
        marginRight: "auto"
    },

});

const LinkPage: React.FC = () => {
    const classes = useStyles();

    return (
        <MainTemplate title="リンクページ">
            <Typography
                component="h3"
                variant="h3"
                color="inherit"
                noWrap
                className={classes.pageTitle}
            >
                リンク
            </Typography>

            <Link href="https://otobou.com/" className={classes.link}>
                <Typography
                    component="h5"
                    variant="h5"
                    color="inherit"
                    noWrap
                    className={classes.pageTitle}
                >
                    おとぼうの不安工房
                </Typography>
            </Link>
        </MainTemplate>
    );
};

export default LinkPage;
