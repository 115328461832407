import React from "react";
import MainTemplate from "../templates/MainTemplate";
import CardContents from "../views/CardContents";

const HomePage: React.FC = () => {
    return (
        <MainTemplate title="トップページ">
            <CardContents/>
        </MainTemplate>
    );
};

export default HomePage;
