import React, {useEffect} from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from "@material-ui/core/Avatar";
// import Accordion from "@material-ui/core/Accordion";
// import AccordionSummary from "@material-ui/core/AccordionSummary";
// import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import axios from 'axios';
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import {IconButton} from "@material-ui/core";
import clsx from 'clsx';
import Divider from "@material-ui/core/Divider";
// import red from "@material-ui/core/colors/red";

const useStyles = makeStyles((theme) => ({
    // root: {
    //     display: 'flex',
    // },
    // details: {
    //     display: 'flex',
    //     flexDirection: 'column',
    // },
    // content: {
    //     flex: '1 0 auto',
    // },
    // cover: {
    //     height: 150,
    //     width: 150,
    // },
    // controls: {
    //     display: 'flex',
    //     alignItems: 'center',
    //     paddingLeft: theme.spacing(1),
    //     paddingBottom: theme.spacing(1),
    // },
    // playIcon: {
    //     height: 38,
    //     width: 38,
    // },
    // icon: {
    //     marginRight: theme.spacing(2),
    // },
    // heroContent: {
    //     backgroundColor: theme.palette.background.paper,
    //     padding: theme.spacing(8, 0, 6),
    // },
    // heroButtons: {
    //     marginTop: theme.spacing(4),
    // },
    cardGrid: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
    },
    // card: {
    //     height: '100%',
    //     display: 'flex',
    //     flexDirection: 'column',
    // },
    // cardMedia: {
    //     paddingTop: '56.25%', // 16:9
    // },
    // cardContent: {
    //     flexGrow: 1,
    // },
    // footer: {
    //     backgroundColor: theme.palette.background.paper,
    //     padding: theme.spacing(6),
    // },
    //
    // tweetUser: {
    //     display: 'flex',
    //     flexDirection: 'row',
    // },

    //
    // heading: {
    //     fontSize: theme.typography.pxToRem(15),
    //     flexBasis: '50.00%',
    //     flexShrink: 0,
    //     padding: "10px 10px",
    // },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
        padding: "10px 0",
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    // avatar: {
    //     backgroundColor: red[500],
    // },
}));

class Blog {
    title: string;
    url: string;
    name: string;
    screenName: string;
    date: Date;
    description: string;

    constructor(title: string, url: string, name: string, screenName: string, date: Date, description: string) {
        this.title = title;
        this.url = url;
        this.name = name;
        this.screenName = screenName;
        this.date = date;
        this.description = description;
    }
}

export default function CardContents() {
    const classes = useStyles();

    const [expanded, setExpanded] = React.useState<string | false>(false);
    // const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    //     console.log("bbbbb")
    //     setExpanded(isExpanded ? panel : false);
    // };

    const handleChange = (panel: string) => {
        setExpanded(expanded !== panel ? panel : false);
    };

    const [blogs, setBlogs] = React.useState<Blog[]>([])

    const host = "/data/latest.json"
    useEffect(() => {
        const fetch = async () => {
            const result = await axios(host);
            const tmpBlogs: Blog[] = [];
            result.data.map((d: Blog)=>{
                return tmpBlogs.push(
                    new Blog(
                        d.title,
                        d.url,
                        d.name,
                        d.screenName,
                        d.date,
                        d.description
                    )
                )
            })
            const tmpBlogs2 = tmpBlogs.sort((a, b) => a.date < b.date ? 1: -1)

                // .sort((a, b) => a.itemM > b.itemM ? 1 : -1)
                // .map((item, i) =>
                //     <div key={i}> {item.matchID} {item.timeM}{item.description}</div>
                // );

            setBlogs(tmpBlogs2)
        }
        fetch()
    }, [])

    return (
        <React.Fragment>
            <CssBaseline />
            <main>

                <Container className={classes.cardGrid} maxWidth="lg">
                    <Grid container spacing={4}>
                        {blogs.map((blog) => (
                            <Grid item key={blogs.indexOf(blog)} xs={12}>
                                <Card>
                                    <CardHeader
                                        avatar={<Avatar alt="{blog.name}">{blog.name.slice(0,1)}</Avatar>}
                                        titleTypographyProps={{variant:'h6' }}
                                        title={
                                            <Link href={blog.url}>
                                                {blog.title}
                                            </Link>
                                        }
                                        subheader={
                                             <Typography>
                                                    <Link href={"https://twitter.com/" + blog.screenName}>
                                                        {blog.name}
                                                    </Link>
                                                    <div>
                                                        {blog.date}
                                                    </div>
                                             </Typography>
                                        }
                                    />
                                    <Divider />
                                    <CardActions disableSpacing>
                                        <IconButton
                                            className={clsx(classes.expand, {
                                                [classes.expandOpen]: expanded,
                                            })}
                                            onClick={() => {handleChange(blogs.indexOf(blog).toString())}}
                                            // // onClick={handleExpandClick}
                                            // aria-expanded={expanded}
                                            // aria-label="show more"
                                        >
                                            <ExpandMoreIcon />
                                        </IconButton>
                                    </CardActions>
                                    <Collapse in={expanded === blogs.indexOf(blog).toString()} timeout="auto" unmountOnExit>
                                        <CardContent>
                                            <Typography paragraph>
                                                {blog.description}
                                            </Typography>
                                        </CardContent>
                                    </Collapse>
                                </Card>

                                {/*<Accordion expanded={expanded === blogs.indexOf(blog).toString()} onChange={handleChange(blogs.indexOf(blog).toString())}>*/}
                                {/*    <AccordionSummary*/}
                                {/*        expandIcon={<ExpandMoreIcon />}*/}
                                {/*        aria-controls="panel1bh-content"*/}
                                {/*        id="panel1bh-header"*/}
                                {/*    >*/}
                                {/*        <Avatar alt="{blog.name}">{blog.name.slice(0,1)}</Avatar>*/}
                                {/*        <Typography className={classes.heading}>*/}
                                {/*        <Link href={blog.url}>*/}
                                {/*                {blog.title}*/}
                                {/*        </Link>*/}
                                {/*        </Typography>*/}
                                {/*        <Typography className={classes.secondaryHeading}>*/}
                                {/*            <Link href={"https://twitter.com/" + blog.screenName}>*/}
                                {/*                {blog.name}*/}
                                {/*            </Link>*/}
                                {/*        </Typography>*/}
                                {/*        <Typography className={classes.secondaryHeading}>*/}
                                {/*            {"(" + blog.date + ")"}*/}
                                {/*        </Typography>*/}
                                {/*    </AccordionSummary>*/}
                                {/*    <AccordionDetails>*/}
                                {/*        <Typography className={classes.secondaryHeading}>*/}
                                {/*            {blog.description}*/}
                                {/*        </Typography>*/}
                                {/*    </AccordionDetails>*/}
                                {/*</Accordion>*/}
                            </Grid>
                        ))}
                    </Grid>
                </Container>
            </main>
        </React.Fragment>
    );
}
